$(function() {

    (function($) { "use strict";

        $(function() {
            var header = $(".start-style");
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();

                if (scroll >= 10) {
                    header.removeClass('start-style').addClass("scroll-on");
                } else {
                    header.removeClass("scroll-on").addClass('start-style');
                }
            });
        });

        //Animation
        $(document).ready(function() {
            $('body.hero-anime').removeClass('hero-anime');
        });

        //Menu On Hover
        $('body').on('mouseenter mouseleave','.nav-item',function(e){
            if ($(window).width() > 750) {
                var _d=$(e.target).closest('.nav-item');_d.addClass('show');
                setTimeout(function(){
                    _d[_d.is(':hover')?'addClass':'removeClass']('show');
                },1);
            }
        });

    })(jQuery);
});




if ($(window).width() > 768) {
    $('.events-container__wrapper__item').on('mouseover', function(){
        var blockHeight = $(this).find('.events-container__wrapper__item__description').innerHeight();
        $(this).find('.top-script').css({"top" : "calc(81% - " + +blockHeight + "px"})
    });

    $('.events-container__wrapper__item').on('mouseout', function(){
        $(this).find('.top-script').css({"top" : "85%"})
    });
}
else {
    $('.events-container__wrapper__item').on('mouseover', function(){
        var blockHeight = $(this).find('.events-container__wrapper__item__description').innerHeight();
        $(this).find('.top-script').css({"top" : "calc(75% - " + +blockHeight + "px"})
    });

    $('.events-container__wrapper__item').on('mouseout', function(){
        $(this).find('.top-script').css({"top" : "82%"})
    });
}


$('.logo-slider__wrapper').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1380,
            settings: {
                slidesToShow: 4
            }
        },

        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3
            }
        },

        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2
            }
        },

        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }

    ]
});

$('.left-arr').click(function(){
    $('.logo-slider__wrapper').slick('slickPrev');
});

$('.right-arr').click(function(){
    $('.logo-slider__wrapper').slick('slickNext');
});







//
// // Init AOS
// AOS.init();